.rcw-conversation-container .rcw-header {
    background-color: #2D2D2D;
    border-radius: 10px 10px 0 0;
    border: 1px solid #2D2D2D;
    border-bottom: none;
    color: #fff;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
    padding: 10px 0 10px;
}

.rcw-header span {
    display: none
}

.rcw-conversation-container .rcw-title {
    font-size: 1.2em;
    margin: 0 !important;
    padding: 5px 0;
}

.rcw-conversation-container .rcw-close-button {
    display: none
}

.rcw-conversation-container .avatar {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    margin-right: 10px;
    vertical-align: middle
}

.rcw-full-screen .rcw-header {
    border-radius: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    position: relative
}

.rcw-full-screen .rcw-title {
    padding: 0 0 15px;
}

.rcw-full-screen .rcw-close-button {
    border: 0;
    display: block;
    position: absolute;
    right: 10px;
    top: 20px;
    width: 40px
}

.rcw-close {
    position: absolute;
    z-index: 100;
    right: 23px;
    top: 0;
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    padding: 6px;
}

.rcw-full-screen .rcw-close {
    width: 20px;
    height: 20px;
}

@media screen and (max-width:800px) {
    .rcw-conversation-container .rcw-header {
        border-radius: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        position: relative
    }
    .rcw-conversation-container .rcw-title {
        padding: 15px 0 15px;
        font-size: 1.5em;
    }
    .rcw-conversation-container .rcw-close-button {
        background-color: inherit;
        display: block;
        position: absolute;
        right: 10px;
        top: 20px;
        box-sizing: unset !important;
        width: 12px;
        height: 24px;
        border: none;
        cursor: pointer;
    }

    .rcw-conversation-container .rcw-close-button::after {
        content: 'X';
        font-size: 20px;
        color: #f4f4f4;
        position: absolute;
        margin: -10px 0 0 -20px;
        border: 1px solid #f4f4f4;
        padding: 0 4px;
        border-radius: 5px;
    }

    .rcw-conversation-container .rcw-close-button img {
        display: none;
    }
    .rcw-conversation-container .rcw-close {
        width: 20px;
        height: 20px
    }
}

.rcw-message {
    margin: 10px;
    display: -ms-flexbox;
    display: flex;
    word-wrap: break-word
}

.rcw-client {
    background-color: #F3F6F8;
    margin-left: auto
}

.rcw-client,
.rcw-response {
    border-radius: 10px;
    padding: 15px;
    max-width: 250px;
    text-align: left
}

.rcw-response {
    background-color: #e0f1f9;
}

.rcw-message-text p {
    margin: 0;
    font-size: 1.1em
}

.rcw-avatar {
    width: 40px;
    height: 40px;
    border-radius: 10%;
    margin-right: 10px;
}

.rcw-snippet {
    background-color: #e0f1f9;
    border-radius: 10px;
    padding: 15px;
    max-width: 250px;
    text-align: left;
}

.rcw-snippet.user {
    background-color: #F2F5F7;
    margin-left: auto;
}

.rcw-snippet-title {
    margin: 0
}

.rcw-snippet-details {
    border-left: 2px solid #f2a900;
    margin-top: 5px;
    padding-left: 10px
}

.rcw-snippet-details a {
    font-size: 12px;
    color: inherit;
}

.quick-button {
    background: none;
    border-radius: 15px;
    border: 2px solid #CFCFD0;
    color: #CFCFD0;
    font-weight: 700;
    padding: 5px 10px;
    cursor: pointer;
    outline: 0
}

.quick-button:hover {
    color: #6C6C6D;
    border-color: #6C6C6D;
}

.quick-list-button.active .quick-button {
    color: #6C6C6D;
    border-color: #6C6C6D;
}

.quick-button:active {
    background: #6C6C6D;
    color: #6C6C6D
}

.loader {
    margin: 10px;
    display: none
}

.loader.active {
    display: -ms-flexbox;
    display: flex
}

.loader-container {
    background-color: #f4f7f9;
    border-radius: 10px;
    padding: 15px;
    max-width: 215px;
    text-align: left
}

.loader-dots {
    display: inline-block;
    height: 4px;
    width: 4px;
    border-radius: 50%;
    background: gray;
    margin-right: 2px;
    animation: a .5s ease infinite alternate
}

.loader-dots:first-child {
    animation-delay: .2s
}

.loader-dots:nth-child(2) {
    animation-delay: .3s
}

.loader-dots:nth-child(3) {
    animation-delay: .4s
}

@keyframes a {
    0% {
        transform: translateY(0)
    }
    to {
        transform: translateY(5px)
    }
}

.rcw-messages-container {
    background-color: #fff;
    height: 42vh;
    max-height: 410px;
    overflow-y: scroll;
    padding-top: 10px;
    -webkit-overflow-scrolling: touch;
    border: 1px solid #6C6C6D;
    border-top: none;
    border-bottom: none;
}

.rcw-full-screen .rcw-messages-container {
    height: 100%;
    max-height: none
}

@media screen and (max-width:800px) {
    .rcw-messages-container {
        height: 100%;
        max-height: none
    }

.rcw-client,.rcw-response,.rcw-snippet {
    max-width: 350px;
}
}

.rcw-sender {
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    background-color: #f4f7f9;
    height: 55px;
    padding: 10px 5px;
    border-radius: 0 0 10px 10px;
    margin-top: 0 !important;
    width: auto !important;
    border: 1px solid #6C6C6D;
    border-top: none;
}

.rcw-new-message {
    width: 100%;
    border: 0;
    background-color: #f4f7f9;
    height: 32px;
    padding: 15px !important;
    margin: 10px !important;
    font-size: 0.9em;
}

.rcw-system-message {
    width: 100%;
    background-color: #fff1c9;
    border-radius: 10px;
    padding: 0 10px;
    margin: 10px !important;
}

.rcw-dialog-message {
    width: 100%;
    background: #F3F6F8;
    padding: 10px;
    text-align: center;
    border-radius: 10px;
    margin: 0 50px;
}

.rcw-dialog-message button {
   color: #4D4D4E;
   border-color: #4D4D4E;
}

.rcw-dialog-message button:hover {

background: #4D4D4E;
color: #FFFFFF;
border-color: #4D4D4E;

}

.rcw-dialog-message h4 {
    margin-bottom: 10px;
}

.rcw-system-message p {
    font-size: 0.8em;
    margin-bottom: 0px !important;
    padding: 10px 0;
}

.rcw-new-message:focus {
    outline: none
}

.rcw-send {
    background: #f4f7f9;
    border: 0;
    padding: 10px !important;
}

.rcw-send img:hover {
    filter: invert(44%) sepia(1%) saturate(836%) hue-rotate(245deg) brightness(93%) contrast(87%);
}

.rcw-send .rcw-send-icon {
    height: 25px;

}

@media screen and (max-width:800px) {
    .rcw-sender {
        border-radius: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0
    }
}

.quick-buttons-container {
    background: #fff;
    overflow-x: auto;
    white-space: nowrap;
    padding: 10px;
    border: 1px solid #6C6C6D;
    border-bottom: none;
    border-top: none;
}

.quick-buttons-container .quick-buttons {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center
}

.quick-buttons-container .quick-buttons .quick-list-button {
    display: inline-block;
    margin-right: 10px;
}

.quick-buttons-container .quick-buttons .quick-list-button button:before {
    position: absolute;
    font-size: 0.65em;
    display: none;
    font-weight: normal;
    margin-top: -23px;
    background: #FFFFFF;
    padding: 2px 3px;
    border-radius: 3px;
}
.quick-buttons-container .quick-buttons .quick-list-button button:hover:before {
    display: block;
}
.quick-buttons-container .quick-buttons .quick-list-button:nth-child(1) button:before {
    content: "Mute sounds";
    margin-left: -35px;
}
.quick-buttons-container .quick-buttons .quick-list-button:nth-child(2) button:before {
    content: "Send image";
    margin-left: -25px;
}
.quick-buttons-container .quick-buttons .quick-list-button:nth-child(3) button:before {
    content: "Rate operator";
    margin-left: -35px;
}
.quick-buttons-container .quick-buttons .quick-list-button:nth-child(4) button:before {
    content: "Rate operator";
    margin-left: -35px;
}
.quick-buttons-container .quick-buttons .quick-list-button:nth-child(5) button:before {
    content: "Quit chat";
    margin-left: -20px;
}

input#image-file {
    display: none;
}

.rcw-file-upload {
    background: none;
    border-radius: 15px;
    border: 2px solid #4D4D4E;
    color: #4D4D4E;
    font-weight: 700;
    padding: 3px 10px;
    cursor: pointer;
    font-size: 14px;
    text-transform: uppercase;
    font-family: Montserrat, sans-serif;
    margin-right: 5px;
    outline: 0
}

.rcw-dialog-message.upload .fileName {
    display: block; padding: 10px 0;
}

.rcw-dialog-message.upload .fileName i{
    margin-right: 5px;
}

.rcw-file-upload:hover {
    background: #4D4D4E;
    border-color: #4D4D4E;
    color: #FFFFFF;
}

@media screen and (max-width:800px) {
    .quick-buttons-container {
        padding-bottom: 15px
    }
}

.rcw-conversation-container {
    -webkit-animation-delay: 0;
    -webkit-animation-duration: .5s;
    -webkit-animation-name: d;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-delay: 0;
    -moz-animation-duration: .5s;
    -moz-animation-name: d;
    -moz-animation-fill-mode: forwards;
    animation-delay: 0;
    animation-duration: .5s;
    animation-name: d;
    animation-fill-mode: forwards;
    border-radius: 10px;
}

.rcw-slide-out {
    -webkit-animation-delay: 0;
    -webkit-animation-duration: .5s;
    -webkit-animation-name: e;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-delay: 0;
    -moz-animation-duration: .5s;
    -moz-animation-name: e;
    -moz-animation-fill-mode: forwards;
    animation-delay: 0;
    animation-duration: .5s;
    animation-name: e;
    animation-fill-mode: forwards
}

.rcw-full-screen .rcw-conversation-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%
}

@media screen and (max-width:800px) {
    .rcw-conversation-container {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        height: 100%
    }
}

.rcw-launcher .rcw-badge {
    position: fixed;
    top: -10px;
    right: -5px;
    background-color: red;
    color: #fff;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 25px;
    border-radius: 50%
}

.rcw-launcher {
    -webkit-animation-delay: 0;
    -webkit-animation-duration: .5s;
    -webkit-animation-name: d;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-delay: 0;
    -moz-animation-duration: .5s;
    -moz-animation-name: d;
    -moz-animation-fill-mode: forwards;
    animation-delay: 0;
    animation-duration: .5s;
    animation-name: d;
    animation-fill-mode: forwards;
    -ms-flex-item-align: end;
    align-self: flex-end;
    background-color: #D74937;
    border: 0;
    border-radius: 50%;
    height: 60px;
    margin-top: 10px;
    width: 60px;
}

.rcw-launcher:focus {
    outline: none
}

.rcw-open-launcher {
    width: 25px;
    -webkit-animation-delay: 0;
    -webkit-animation-duration: .5s;
    -webkit-animation-name: c;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-delay: 0;
    -moz-animation-duration: .5s;
    -moz-animation-name: c;
    -moz-animation-fill-mode: forwards;
    animation-delay: 0;
    animation-duration: .5s;
    animation-name: c;
    animation-fill-mode: forwards;
    max-width: 30px;
}

.rcw-close-launcher {
    width: 20px;
    -webkit-animation-delay: 0;
    -webkit-animation-duration: .5s;
    -webkit-animation-name: b;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-delay: 0;
    -moz-animation-duration: .5s;
    -moz-animation-name: b;
    -moz-animation-fill-mode: forwards;
    animation-delay: 0;
    animation-duration: .5s;
    animation-name: b;
    animation-fill-mode: forwards
}

@media screen and (max-width:800px) {
    .rcw-launcher {
        bottom: 0;
        margin: 20px;
        position: fixed;
        right: 0
    }
    .rcw-hide-sm {
        display: none
    }
}

@keyframes b {
    0% {
        transform: rotate(-90deg)
    }
    to {
        transform: rotate(0)
    }
}

@keyframes c {
    0% {
        transform: rotate(90deg)
    }
    to {
        transform: rotate(0)
    }
}

@keyframes d {
    0% {
        opacity: 0;
        transform: translateY(10px)
    }
    to {
        opacity: 1;
        transform: translateY(0)
    }
}

@keyframes e {
    0% {
        opacity: 1;
        transform: translateY(0)
    }
    to {
        opacity: 0;
        transform: translateY(10px)
    }
}

.rcw-widget-container {
    bottom: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0 20px 20px 0;
    max-width: 370px;
    position: fixed;
    right: 0;
    z-index: 1005;
    border-radius: 10px;
}

.rcw-opened {
    width: 90vw
}

.rcw-full-screen {
    margin: 0;
    max-width: none;
    width: 100%
}

.rcw-full-screen.rcw-opened {
    width: 100%;
    height: 100%
}

@media screen and (max-width:800px) {
    .rcw-widget-container {
        margin: 0;
        max-width: none;
        width: 100%
    }
    .rcw-widget-container.rcw-opened {
        height: 100%
    }
}